import Carousel from "react-bootstrap/Carousel";
import { useEffect, useState } from "react";

const ImportedImage = ({ projectId, filename, innerClassName }) => {
  const [url, setUrl] = useState("");

  useEffect(async () => {
    const resolvedUrl = await getImageAsync(projectId, filename);
    setUrl(resolvedUrl.default);
  }, [filename]);

  const getImageAsync = async (project, img) => {
    try {
      const path = `${project}/Images/${img}`;
      const response = await import(`../../Projects/${path}`);
      return response;
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  return <img src={url} alt=" " className={innerClassName}></img>;
};

export default ImportedImage;
