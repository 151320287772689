import importAll from "../common";


const GimmeThatMusic = {
    id: "GimmeThatMusic",
    name: "Gimme That Music",
    coverImage: "gtm.png",
    desc: {
            gb:"A Windows application for downloading audio from YouTube videos.",
            pl:"Aplikacja na systemy Windows służąca do pobierania audio z filmów umieszczonych w serwisie YouTube."
    },
    year: "2016",
    fulldesc: {
        gb:"Gimme That Music is a Windows application for downloading audio from YouTube videos. In addition to downloading files, the program allows you to add basic MP3 tags such as song title, artist or a photo associated with the song. The program has a simple integration with the Foobar2000 player, allowing you to add downloaded files to a playlist.",
        pl:"Gimme That Music to aplikacja na systemy Windows służąca do pobierania audio z filmów umieszczonych w serwisie YouTube. Poza pobieraniem plików program umożliwia dodawanie podstawowych tagów MP3 takich jak tytuł utworu, wykonawca czy powiązane z utworem zdjęcie. Program posiada prostą integrację z odtwarzaczem Foobar2000, pozwalając na dodawanie pobranych plików do playlisty."
    },
    techstack: "WPF,YoutubeExplode,FFmpeg,C#",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/))
}

  

export default GimmeThatMusic;