import importAll from "../common";


const QuickRenamer = {
    id: "QuickRenamer",
    name: "Quick Renamer",
    coverImage: "qr.png",
    desc: {
        gb:"An application that allows you to rename multiple files according to a given pattern",
        pl:"Aplikacja pozwalająca na masową zamianę nazw plików według podanego wzorca"
    },
    year: "2018",
    fulldesc: {
        gb:"Quick Renamer was created as an application to quickly rename song files. When I prepared music CDs for my car, the radio automatically sorted all songs alphabetically, ignoring the order in the playlist. For this purpose, I created QuickRenamer, which adds a number to the file name that corresponds to the order in the previously prepared playlist according to a specific pattern. The user can define whether the songs should be preceded by a number or letters, and also manually correct the order of the songs by dragging their names on the list.",
        pl:"Quick Renamer powstał jako aplikacja przeznaczona do szybkiej zamiany nazw plików piosenek. Nagrywając płyty CD z muzyką do samochodu zauważyłem, że samochodowe radio automatycznie sortowało utwory w kolejności alfabetycznej, ignorując kolejność na playliście. W tym celu stworzyłem QuickRenamer, który dodaje do nazwy pliku numer odpowiadający kolejności na przygotowanej wcześniej playliście według konkretnego wzorca. Użytkownik może określić czy utwory powinny być poprzedzone liczbą lub literami, a także ręcznie poprawić kolejność utworów przeciągając ich nazwy na liście."
    },
    techstack: "WPF, C#",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/))
}

  

export default QuickRenamer;