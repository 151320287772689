import importAll from "../common";


const HowIsMarsDoing = {
    id: "HowIsMarsDoing",
    name: {"gb": "How is Mars Doing?",
            "pl": "How is Mars Doing?"
            },
    coverImage: "1.jpg",
    desc: {
        "gb": "An Android/iOS app which lets you see photos from rovers on Mars, made for a university course",
        "pl": "An Android/iOS app which lets you see photos from rovers on Mars, made for a university course"
    },
    year: "2022",
    fulldesc: {
        "gb": "'How is Mars Doing' is a small app designed to showcase NASA's Picture of the Day from Mars and a selection of photos captured by Mars rovers (Opportunity, Spirit, Curiosity) on a specific date. Each photo is accompanied by key details, offering users an engaging glimpse into the Martian surface.<br>This project was developed as part of a university course at Gdańsk University of Technology.",
        "pl": "'How is Mars Doing' is a small app designed to showcase NASA's Picture of the Day from Mars and a selection of photos captured by Mars rovers (Opportunity, Spirit, Curiosity) on a specific date. Each photo is accompanied by key details, offering users an engaging glimpse into the Martian surface.<br>This project was developed as part of a university course at Gdańsk University of Technology."
    },
    techstack: "Flutter",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos: []
}

  

export default HowIsMarsDoing;