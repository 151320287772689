import { useState } from "react";
import Sticky from "react-sticky-el";
import "./Header.css";
import pl from './PL.svg';
import eng from './GB.svg';

const Header = ({languageCallback}) => {

    const [currentLanguage, setCurrentLanguage] = useState("eng");



    return(
        //<div className="navbar sticky-top">
        // <Sticky stickyClassName="lang">
        //     <div className="dropdown">
        //         <button className="btn btn-outline dropdown-toggle" type="button" 
        //                 id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //             {/*<span className={`flag-icon flag-icon-${currentLanguage}`}></span>*/}
        //             <img src={(currentLanguage=="pl") ? pl : eng} className="flag"></img>
        //         </button>
        //         <div className="dropdown-menu bg-transparent text-light" style={{border: 0+"px", width: 1+"px !important"}} aria-labelledby="dropdownMenuButton">
        //             <button className="dropdown-item bg-transparent lang-btn" onClick={(e) => {
        //                 setCurrentLanguage("pl");
        //                 languageCallback('pl');
        //             }}><img src={pl} className="flag"></img></button>
        //             <button className="dropdown-item bg-transparent lang-btn" onClick={(e) => {
        //                 setCurrentLanguage("gb");
        //                 languageCallback('gb');
        //             }}><img src={eng} className="flag"></img></button>
        //         </div>
        //     </div>
        // </Sticky>
       // </div>
       <div
       ></div>
    )
}

export default Header;