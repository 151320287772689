import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./ContactMe.css";

const ContactMe = ({ props }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_6go3jqp", "template_stg1j5m", form.current, {
        publicKey: "vx3kAVKMBn88nWx78",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="contact-background">
        <h3>There are more things that I did, some are more personal, others I can't really talk about, but if you are interested...</h3>
        <br/>
      <form className="contact-form" ref={form} onSubmit={sendEmail}>
        <h2>📧 Let's get in touch! 📧</h2>
        <label>Name</label>
        <input className="form-control w-50" type="text" name="user_name" />
        <label>Email</label>
        <input className="form-control w-50" type="email" name="user_email" />
        <label>Message</label>
        <textarea className="form-control" name="message" />
        <input className="btn btn-primary" type="submit" value="Send" />
      </form>
    </div>
  );
};

export { ContactMe };
