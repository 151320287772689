import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import Sticky from 'react-sticky-el';
import "./Cover.css";

import cvpl from '../../CV/pl/Gracjan_Puch.pdf';
import cveng from '../../CV/eng/Gracjan_Puch.pdf';

const Cover = ({currentLanguage}) => {

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [showCVLinks, setShowCVLinks] = useState(false);
    return(
        <div className="text-center headerContainer">
            <div>
                <Sticky stickyClassName="stickyHeader" dontUpdateHolderHeightWhenSticky={true}>
                    <h1>Gracjan Puch</h1>
                    <p className="subtext">📱 Mobile apps, games, VR and AR 🎮</p>

                <ul className="list-inline">
                    <li className="list-inline-item">
                        <a className="btn btn-lg btn-outline-light" href="https://github.com/Pusiu" role="button">
                            <FontAwesomeIcon icon={faGithub}/>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a className="btn btn-lg btn-outline-light" href="https://www.linkedin.com/in/gracjan-puch-826a83207/" role="button">
                            <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a className="btn btn-lg btn-outline-light" href="mailto:gracjan.puch@gmail.com" role="button">
                            <i className="far fa-envelope"></i>
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </a>
                    </li>
                </ul>
                </Sticky>
                {/* {
                    (!showCVLinks) ?
                    <button className="btn btn-lg btn-outline-primary" onClick={() => {
                        setShowCaptcha(true);
                    }}>CV</button>
                    : null
                } */}
                {
                    (showCaptcha) ? 
                        <ReCAPTCHA sitekey="6Le0hLMaAAAAACMG3EU25mm_GQlm-nca8WY39Sgm" theme="dark" onChange={(val) => {
                            if (val)
                            {
                                setShowCaptcha(false);
                                setShowCVLinks(true);
                            }
                        }}/> 
                        : null
                }
                {
                    (showCVLinks) ?
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a className="btn btn-lg btn-outline-primary" href={cvpl}>CV (PL)</a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-lg btn-outline-primary" href={cveng}>CV (ENG)</a>
                            </li>
                        </ul>
                    : null
                }
                <div id="scroll">
                    <a href="#projects"><span></span><span></span><span></span></a>
                </div>
            </div>

        </div>
    );
}

export default Cover;