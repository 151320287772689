import './App.css';
import Cover from './Components/Cover/Cover';
import Projects from './Components/ProjectsComponent/ProjectsComponent';
import Header from './Components/Header/Header';
import { useState } from 'react';
import { ContactMe } from './Components/ContactMe/ContactMe';

function App() {

  // const [currentLanguage, setCurrentLanguage] = useState(navigator.language.includes("pl") ? "pl" : "gb");
  const [currentLanguage, setCurrentLanguage] = useState("gb");
  const setLang = (lang) => {
    setCurrentLanguage(lang);
  }

  return (
    <div className="">
      <Header languageCallback={setLang} ></Header>
      <Cover currentLanguage={currentLanguage}></Cover>
      <Projects currentLanguage={currentLanguage}></Projects>
      <ContactMe></ContactMe>
    </div>
  );
}

export default App;
