import importAll from "../common";

const SiekieraIMieczem = {
    id: "SiekieraIMieczem",
    name: "Siekierą i mieczem",
    coverImage: "siekieraimieczem.jpg",
    desc: {
        gb: "Adventure/survival game created for week long gamejam 'Stay at home, create games'",
        pl:"Gra przygodowa/survival stworzona w ramach tygodniowego game jamu 'Zostań w domu rób gry'"
    },
    year: "2020",
    fulldesc: {
        gb: "'Siekierą i mieczem' is a 3D top-down adventure game created for week long gamejam 'Stay at home, create games' in 2020. The player's  task is to rebuild a destroyed village. To do this, the player collects wood and stones from the surrounding forests to help the villagers repair damaged buildings, all while avoiding bandits patrolling the area.",
        pl: "Siekierą i Mieczem to trójwymiarowa gra przygodowa z widokiem z góry, w której zadaniem gracza jest odbudować zniszczoną osadę. W tym celu gracz zbiera drewno oraz kamienie z okolicznych lasów by pomóc mieszkańcom w naprawie zniszczonych budynków, jednocześnie unikając bandytów grasujących w okolicy."
    },
    techstack: "Unity,Blender,C#",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos:["https://www.youtube.com/embed/pfehM_2tvWQ"],
    externalResources: [{title:"Itch.io", href:"https://pusiu.itch.io/siekier-i-mieczem"}, {title: "GitHub", href: "https://github.com/Pusiu/siekiera-i-mieczem"}]
}

export default SiekieraIMieczem;